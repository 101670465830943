<template>
  <div class="detalle-cuadre mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Detalle ventas del
            {{ moment($route.params.inicio).format("ll") }} al
            {{ moment($route.params.fin).format("ll") }}
            <!-- <v-spacer></v-spacer>
            <v-btn color="black" dark>
              Volver
            </v-btn> -->
          </v-card-title>
          <v-card-text>
            <v-row dense align="center">
              <v-col cols="12" md="6" sm="12">
                <v-card elevation="0" outlined>
                  <v-card-text v-if="profesional != null">
                    <v-row align="center" no-gutters>
                      <v-col>
                        <strong>
                          {{ profesional.nombre }}
                        </strong>
                        <br />
                        <strong> Cc: {{ profesional.cedula }} </strong>
                        <br />
                        <strong> Tel: {{ profesional.telefono }} </strong>
                        <br />
                        <strong>
                          {{ profesional.apodo }}
                        </strong>
                        <br />
                        <v-btn color="black" dark @click="download()">
                          <v-icon left>mdi-download</v-icon>
                          Descargar
                        </v-btn>
                      </v-col>
                      <v-col align="right">
                        <v-avatar size="85">
                          <img
                            :src="
                              $store.state.centro.route +
                                'profesionales/' +
                                profesional.foto
                            "
                          />
                        </v-avatar>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-expansion-panels accordion>
                  <v-expansion-panel v-for="(item, i) in servicios" :key="i">
                    <v-expansion-panel-header>
                      <span class="primary--text">
                        {{ item.servicio }}
                      </span>
                      <div class="primary--text text-right">
                        <v-chip color="primary">
                          {{ item.cantidad }}
                        </v-chip>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>Factura</th>
                              <th>Cliente</th>
                              <th>Porcentaje</th>
                              <th>Ganancia</th>
                              <th>Productos de consumo</th>
                              <th>Total servicio</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(factura, index) in item.detalle"
                              :key="index"
                            >
                              <td>
                                <router-link
                                  :to="`/detallefactura/${factura.id}`"
                                >
                                  {{ factura.folio | folio }}
                                </router-link>
                              </td>
                              <td>{{ factura.cliente }}</td>
                              <td>{{ factura.porcentaje }}%</td>
                              <td>{{ parseFloat(factura.total) | currency }}</td>
                              <td>
                                <router-link
                                  v-if="factura.consumos != 0"
                                  :to="`/detallefactura/${factura.id}`"
                                >
                                  {{ factura.consumos | currency }}
                                </router-link>
                                <span v-else>
                                  {{ factura.consumos | currency }}
                                </span>
                              </td>
                              <td>{{ parseFloat(factura.valor) | currency }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
,
<script>
import XLSX from "xlsx";
import * as moment from "moment";
moment.locale("es");
export default {
  name: "detalleCuadre",
  data: () => ({
    profesional: null,
    servicios: [],
  }),
  methods: {
    loadDetalleCaja() {
      const body = {
        route: "/detalle_cuadre_caja",
        params: {
          date: {
            inicio: this.$route.params.inicio,
            fin: this.$route.params.fin,
          },
          id: this.$route.params.id,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.servicios = response.data.data.map((s) => {
            s.detalle = s.detalle.map((d) => {
              d.consumos = parseFloat(d.consumos);
              return d;
            });
            return s;
          });
        }
      });
    },
    loadProfesionalDetalle() {
      const body = {
        route: "/profesional_detalle",
        params: {
          id: this.$route.params.id,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) this.profesional = response.data.data;
      });
    },
    download() {
      const name = `detalle-caja-${this.profesional.cedula}`;
      let body = [
        {
          Columna_A: this.profesional.nombre,
          Columna_B: "",
          Columna_C: "",
          Columna_D: "",
          Columna_E: "",
          Columna_F: "",
        },
        {
          Columna_A: this.profesional.cedula,
          Columna_B: "",
          Columna_C: "",
          Columna_D: "",
          Columna_E: "",
          Columna_F: "",
        },
        {
          Columna_A: "Fecha inicial",
          Columna_B: moment(this.$route.params.inicio).format("ll"),
          Columna_C: "",
          Columna_D: "",
          Columna_E: "",
          Columna_F: "",
        },
        {
          Columna_A: "Fecha final",
          Columna_B: moment(this.$route.params.fin).format("ll"),
          Columna_C: "",
          Columna_D: "",
          Columna_E: "",
          Columna_F: "",
        },
      ];

      const divider = {
        Columna_A: "",
        Columna_B: "",
        Columna_C: "",
        Columna_D: "",
        Columna_E: "",
        Columna_F: "",
      };
      this.servicios.forEach((servicio) => {
        body.push(divider);
        const header = {
          Columna_A: "Servicio",
          Columna_B: servicio.servicio,
          Columna_C: "",
          Columna_D: "",
          Columna_E: "Cantidad",
          Columna_F: servicio.cantidad,
        };
        body.push(header);
        const header_detalle = {
          Columna_A: "Factura",
          Columna_B: "Cliente",
          Columna_C: "Porcentaje",
          Columna_D: "Ganancia",
          Columna_E: "Productos de consumo",
          Columna_F: "Total servicio",
        };
        body.push(header_detalle);
        servicio.detalle.forEach((detalle) => {
          let data_detalle = {
            Columna_A: this.$options.filters.folio(detalle.folio),
            Columna_B: detalle.cliente,
            Columna_C: detalle.porcentaje,
            Columna_D: parseFloat(detalle.total),
            Columna_E: parseFloat(detalle.consumos),
            Columna_F: parseFloat(detalle.valor),
          };
          body.push(data_detalle);
        });
      });
      let excel = XLSX.utils.json_to_sheet(body);
      const workbook = XLSX.utils.book_new();
      const filename = name;
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    moment(data) {
      return moment(data);
    },
  },
  created() {
    this.loadDetalleCaja();
    this.loadProfesionalDetalle();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes", "recepcion"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
};
</script>
